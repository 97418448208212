import React from 'react';
import {Cookie} from "../types/cookie";
import parse from "html-react-parser";

type CookieProps = {
    cookie : Cookie
}

export function CookieInfo({cookie} : CookieProps) {
    return (
        <div className='cookie__banner--cookieInfo'>
            <div className='cookie__banner--cookieInfo-title'>
                {cookie.title}
            </div>
            <div className='cookie__banner--cookieInfo-description'>
                {parse(cookie.description)}
            </div>
            <div className='cookie__banner--cookieInfo-sessionTime'>
                {cookie.sessionTime}
            </div>
            <div className='cookie__banner--cookieInfo-type'>
                {cookie.type}
            </div>
        </div>
    )
}
