import React from 'react';
import {CookieInfo} from "./CookieInfo";
import {CookieGroup} from "../types/cookieGroup";

type CookieListProps = {
    group : CookieGroup
}

export function CookieList({group} : CookieListProps) {
    return (
        <div className='cookie__banner--cookielist-container'>
            <div className='cookie__banner--cookielist'>
                {group.cookies.map((cookie, index) => <CookieInfo key={index} cookie={cookie}/>)}
            </div>
        </div>
    )
}
