import React from 'react';
import parse from 'html-react-parser';
import {Labels} from "../types/labels";
import {CookieGroup} from "../types/cookieGroup";

type CookieBannerProps = {
    groups : CookieGroup[]
    labels : Labels
    setCurrentCookieSettings : (saveGroups : string[]) => void
}

export function CookieBanner({labels, groups, setCurrentCookieSettings} : CookieBannerProps) {

    const saveGroups = groups.map((group : { groupKey : string }) => group.groupKey);

    return (
        <div className="cookie__banner--content">
            <div className="cookie__text">
                {parse(labels.consentText)}
            </div>
            <div className="cookie__row--navigation">
                <button className="btn btn-primary cookie__row--button-default"
                        onClick={() => setCurrentCookieSettings(saveGroups)}>{labels.acceptLabel}</button>
            </div>
        </div>
    )
}
