import React, {useState} from 'react';
import parse from 'html-react-parser';
import {Labels} from '../types/labels';
import {CookieGroup} from '../types/cookieGroup';
import {ManageSettings} from './ManageSettings';

type CookieBannerProps = {
    groups : CookieGroup[]
    labels : Labels
    currentCookieSettings: string[]
    setCurrentCookieSettings : (saveGroups : string[]) => void
    saveCookieSettings : (event: React.MouseEvent<HTMLButtonElement>, mode : string) => void
}

export function CookieBannerAdvanced({labels, groups, currentCookieSettings, setCurrentCookieSettings, saveCookieSettings}: CookieBannerProps) {

    const [showManageSettings, setShowManageSettings] = useState(false);

    return (
        <>
            <div className={`cookie__banner--content ${showManageSettings ? 'hide--content' : ''}`}>
                <div className="cookie__text">
                    {parse(labels.consentText)}
                </div>
                <div className="cookie__row--navigation">
                    <button className="btn btn-primary cookie__row--button-default cookie__row--button-required"
                            onClick={(event) => saveCookieSettings(event,'required')}>{labels.acceptRequiredLabel}</button>
                    <button className="btn btn-primary cookie__row--button-default"
                            onClick={(event) => saveCookieSettings(event,'all')}>{labels.acceptDefaultLabel}</button>
                    <button className="btn btn-primary cookie__row--button-advanced"
                            onClick={() => setShowManageSettings(true)}>{labels.advancedLabel}</button>
                </div>
            </div>

            {showManageSettings &&
                <ManageSettings
                    labels={labels}
                    groups={groups}
                    currentCookieSettings={currentCookieSettings}
                    setCurrentCookieSettings={setCurrentCookieSettings}
                    setShowManageSettings={setShowManageSettings}
                    saveCookieSettings={saveCookieSettings}
                />
            }
        </>
    )
}
