export default function activateScripts(scriptGroups: string[]) {
    scriptGroups.forEach(groupKey => {
        const elements: HTMLElement[] = Array.from(document.querySelectorAll(`[data-nezzo-cookie-group=${groupKey}]`)) as HTMLElement[];

        elements.forEach((element : HTMLElement) => {
            let newTag;
            const elementAttributes = element.attributes;

            // Due to an "execution" bug in Firefox, we need a new element.
            // In Firefox, the script is not executed after an update or element cloning.
            // So we add all the original attributes, except the "type" attribute, to the new element.
            if (element.hasAttribute('data-nezzo-transform-tag')) {
                const transformedTag = element.dataset.nezzoTransformTag;

                if (transformedTag !== undefined) {
                    newTag = document.createElement(transformedTag);
                }

            } else {
                newTag = document.createElement('script');
                newTag.setAttribute('type', 'text/javascript');
            }

            for (const elementAttribute of Object.entries(elementAttributes)) {
                if (elementAttribute[1].name !== 'type' && elementAttribute[1].name !== 'data-nezzo-transform-tag' && elementAttribute[1].name !== 'data-nezzo-cookie-group') {
                    newTag?.setAttribute(elementAttribute[1].name, elementAttribute[1].value);
                }
            }

            if (newTag !== undefined) {
                newTag.innerHTML = element.innerHTML;

                if (element.parentElement !== null) {
                    element.parentElement.insertBefore(newTag, element)
                }

                element.remove();
            }
        });
    });
}
