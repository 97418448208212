import React from 'react';
import ReactDOM from 'react-dom';
import AdvancedCookieBannerApp from './AdvancedCookieBannerApp';

const rootElement = document.querySelector('[data-nezzo-cookie]');
const settingsScriptTag = document.querySelector('[data-nezzo-cookie-settings]');

if (rootElement && settingsScriptTag) {
    const settings = JSON.parse(settingsScriptTag ? settingsScriptTag.innerHTML : '{}');
    const isAdvanced = settings.hasOwnProperty('advanced');
    const groups = (isAdvanced) ? settings.advanced.groups : [{groupKey: 'default'}];
    const triggerLinks: HTMLElement[] = Array.from(document.querySelectorAll('[data-nezzo-cookie-show-settings-trigger]')) as HTMLElement[];

    const labels = (isAdvanced) ? {
        'consentText': settings.consentText,
        'acceptLabel': settings.acceptLabel,
        'acceptRequiredLabel': settings.advanced.acceptRequiredLabel,
        'acceptDefaultLabel': settings.advanced.acceptDefaultLabel,
        'advancedLabel': settings.advanced.advancedLabel,
        'title': settings.advanced.title,
        'description': settings.advanced.description,
        'note': settings.advanced.note,
        'cancelLabel': settings.advanced.cancelLabel,
        'saveLabel': settings.advanced.saveLabel,
    } : settings;

    ReactDOM.render(
        <React.StrictMode>
            <AdvancedCookieBannerApp
                isAdvanced={isAdvanced}
                groups={groups}
                labels={labels}
                triggerLinks={triggerLinks}
            />
        </React.StrictMode>,
        rootElement
    );
}
