import React, {useEffect, useState} from 'react';
import './AdvancedCookieBannerApp.css';
import {hasCookie, readCookieSettings, removeCookie, saveCookie} from './utility/cookie';
import {CookieBanner} from "./components/CookieBanner";
import {CookieBannerAdvanced} from "./components/CookieBannerAdvanced";
import {EditSettingsPortal} from './components/EditSettingsPortal';
import {Labels} from "./types/labels";
import {CookieGroup} from "./types/cookieGroup";
import activateScripts from './utility/activateScripts';

type AdvancedCookieBannerAppProps = {
    isAdvanced : boolean
    groups : CookieGroup[]
    labels : Labels
    triggerLinks : HTMLElement[]
}

function AdvancedCookieBannerApp({isAdvanced, groups, labels, triggerLinks} : AdvancedCookieBannerAppProps) {
    const [currentCookieSettings, setCurrentCookieSettings] = useState<string[]>(readCookieSettings(groups));
    const [showCookieBanner, setShowCookieBanner] = useState<boolean>(!hasCookie());

    const saveCookieSettings = (event: React.MouseEvent<HTMLButtonElement>, mode: string) => {
        const requiredGroups = groups.filter(group => group.required).map(group => group.groupKey);
        const allGroups = groups.map(group => group.groupKey);
        let cookieSettings: string[] = [];

        switch (mode) {
            case 'required':
                cookieSettings = requiredGroups
                break
            case 'all':
                cookieSettings = allGroups
                break
            case 'custom':
            default:
                cookieSettings = currentCookieSettings;
            }

        if ((cookieSettings.length) > 0) {
            saveCookie(cookieSettings);
            activateScripts(cookieSettings);
        } else {
            removeCookie();
        }

        setCurrentCookieSettings(cookieSettings);
        setShowCookieBanner(false);
    };

    useEffect(() =>{
        if (hasCookie()) {
            activateScripts(currentCookieSettings);
        }
    }, [currentCookieSettings]);

    return (
        <>
            {triggerLinks.map((domNode, index) => <EditSettingsPortal onClick={() => setShowCookieBanner(true)} domNode={domNode} key={index}/>)}

            {showCookieBanner &&
                <div className="AdvancedCookieBannerApp cookie__banner">
                    {isAdvanced ?
                        <CookieBannerAdvanced
                            labels={labels}
                            groups={groups}
                            currentCookieSettings={currentCookieSettings}
                            setCurrentCookieSettings={setCurrentCookieSettings}
                            saveCookieSettings={saveCookieSettings}
                        />
                    :
                        <CookieBanner
                            labels={labels}
                            groups={groups}
                            setCurrentCookieSettings={setCurrentCookieSettings}
                        />
                    }
              </div>
          }
      </>
  );
}

export default AdvancedCookieBannerApp;
