import Cookies from 'js-cookie';
import {CookieGroup} from '../types/cookieGroup';

export function readCookieSettings(groups: CookieGroup[]): string[] {
    // due to a cross-origin error do not json parse here
    const cookieContent: string | undefined = Cookies.get('nezzo-cookie-consent');

    if (cookieContent === undefined) {
        return groups.filter(group => group.checked).map(group => group.groupKey);
    } else {
        return JSON.parse(cookieContent);
    }
}

export function hasCookie(): boolean {
    return typeof (Cookies.get('nezzo-cookie-consent')) === 'string';
}

export function saveCookie(groups: string[]) {
    Cookies.set('nezzo-cookie-consent', groups, {
        path: '/',
        expires: 31
        // sameSite: 'none' and secure: true , currently doesn't work in chrome
    });
}

export function removeCookie() {
    Cookies.remove('nezzo-cookie-consent');
}
