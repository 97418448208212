import React from 'react';
import parse from 'html-react-parser';
import {CookieGroup} from '../types/cookieGroup';
import {Labels} from '../types/labels';
import {ListGroupItem} from './ListGroupItem';
import {hasCookie} from "../utility/cookie";

type ManageSettingsProps = {
    groups : CookieGroup[]
    labels : Labels
    currentCookieSettings : string[]
    setCurrentCookieSettings : (saveGroups : string[]) => void
    setShowManageSettings : (manageSettings : boolean) => void
    saveCookieSettings : (event: React.MouseEvent<HTMLButtonElement>, mode : string) => void
}

export function ManageSettings({labels, groups, currentCookieSettings, setCurrentCookieSettings, setShowManageSettings, saveCookieSettings}: ManageSettingsProps) {

    const handleToggle = (groupKey: string, isChecked: boolean) => {
        const updatedCookieSettings = [...currentCookieSettings];

        if (isChecked) {
            updatedCookieSettings.push(groupKey)
        } else {
            const index = updatedCookieSettings.indexOf(groupKey);
            updatedCookieSettings.splice(index, 1);
        }

        setCurrentCookieSettings(updatedCookieSettings)
    };

    const groupListing = groups.map(group => <ListGroupItem
            group={group}
            key={group.groupKey}
            isChecked={hasCookie() ? currentCookieSettings.includes(group.groupKey) : group.checked}
            handleToggle={(isChecked) => handleToggle(group.groupKey, isChecked)}
        />
    );


    return (
        <div className="cookie__banner--config">
            <form>
                <h1 className="cookie__banner--title">{labels.title}</h1>
                <div className="cookie__banner--description">{parse(labels.description)}</div>
                <div className="cookie__banner--list-groups">
                    <ul className="cookie__banner--list">{groupListing}</ul>
                </div>
                <div className="cookie__banner--note">{parse(labels.note)}</div>
                <div className="cookie__row--navigation">
                    <button className="btn btn-primary cookie__row--button-cancel"
                            onClick={() => setShowManageSettings(false)}>
                        {labels.cancelLabel}
                    </button>
                    <button onClick={(event) => saveCookieSettings(event,'custom')} className="btn btn-primary cookie__row--button-save" >
                        {labels.saveLabel}
                    </button>
                </div>
            </form>
        </div>
    )
}
