import React, {useState} from 'react';
import parse from 'html-react-parser';
import {CookieGroup} from '../types/cookieGroup';
import {CookieList} from "./CookieList";

type ListGroupItemProps = {
    group : CookieGroup
    isChecked : boolean
    handleToggle : (isChecked: boolean) => void
}

export function ListGroupItem({group, isChecked, handleToggle} : ListGroupItemProps) {

    const [isCurrentChecked, setIsCurrentChecked] = useState<boolean>(isChecked);

    const handleTrigger = () => {
        const newChecked = !isCurrentChecked;
        setIsCurrentChecked(newChecked);
        handleToggle(newChecked);
    }

    const [showCookieList, setShowCookieList] = useState<boolean>(false);

    const hasCookieList = (group.cookies && group.cookies.length > 0)

    const toggleCookieList = () => {
        if (showCookieList) {
            setShowCookieList(false)
        } else {
            setShowCookieList(true)
        }
    }

    return (
        <li className={`cookie__banner--item ${group.required ? "default--settings" : ''}`}>
            <div className={`custom-control cookie__banner--control ${hasCookieList ? " hasCookielist" : ''}`}>
                {hasCookieList &&
                    <div className="cookie__banner--control-cookies">
                        <span className={`cookie__banner--control-cookies-icon ${showCookieList ? " cookie--list-open" : ''}`} onClick={toggleCookieList} />
                    </div>
                }
                <input type="checkbox" className="custom-control-input cookie__banner--control-input"
                       id={group.groupKey} checked={isCurrentChecked} disabled={group.required} onChange={handleTrigger} />
                <label className="custom-control-label cookie__banner--control-label" htmlFor={group.groupKey} >
                    <span>{group.title}</span>
                    { hasCookieList && <span className='cookie__banner--control-counter'>
                        <span>{group.cookies.length}</span>
                    </span> }
                </label>
            </div>
            <div className="cookie__banner--item-description">{parse(group.description)}</div>

            { hasCookieList && showCookieList && <CookieList group={group} /> }
        </li>
    )
}
