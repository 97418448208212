import React from 'react';
import ReactDOM from 'react-dom';

type EditSettingsPortalProps = {
    onClick: () => void,
    domNode: HTMLElement
}

export function EditSettingsPortal({onClick, domNode}: EditSettingsPortalProps) {
    return ReactDOM.createPortal(
        <span onClick={onClick} className="cookie__banner--trigger">
            {domNode.dataset.nezzoCookieShowSettingsTrigger}
        </span>, domNode
    );
}
